<template>
  <svg
    version="1.1"
    id="Lager_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 250 250"
    style="enable-background: new 0 0 250 250"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M126.8,249.9C56.4,250.8,0.2,194.4-0.1,125.7C-0.4,56.2,55.4,0,124.7-0.1C194-0.2,249.5,55.7,249.9,124
		C250.4,193.5,194.6,248.9,126.8,249.9z M124.9,183.3C125,183.2,124.9,183.5,124.9,183.3c1.7,0,3.3,0,5,0c8.8-0.2,17.6-0.3,26.3-0.5
		c9.8-0.3,19.5-0.8,29.2-2c10.9-1.4,17.7-7.6,19.9-18.3c2.2-10.8,2.6-21.8,2.8-32.8c0.1-7-0.1-14-0.4-20.9c-0.3-7.4-0.9-14.8-2.4-22
		c-1.9-9.3-7.4-15.4-16.7-17.4c-3.7-0.8-7.6-1.2-11.4-1.5c-19.1-1.4-38.3-1.8-57.4-1.6c-8.8,0.1-17.6,0.3-26.3,0.5
		c-9.8,0.3-19.5,0.8-29.2,2C53.3,70.1,46.5,76.3,44.4,87c-2.2,10.8-2.6,21.8-2.8,32.8c-0.1,7,0.1,14,0.4,20.9
		c0.3,7.4,0.9,14.8,2.4,22c1.9,9.4,7.4,15.5,16.8,17.5c4.8,1,9.8,1.5,14.7,1.7C92.3,182.7,108.6,182.8,124.9,183.3z"
      />
      <path
        class="st1"
        d="M124.9,183.3c-16.3-0.6-32.7-0.7-49-1.4c-4.9-0.2-9.9-0.7-14.7-1.7c-9.4-2-14.9-8.1-16.8-17.5
		c-1.5-7.3-2.1-14.6-2.4-22c-0.3-7-0.5-14-0.4-20.9c0.2-11,0.6-22,2.8-32.8c2.1-10.7,9-16.9,19.9-18.3c9.7-1.3,19.4-1.7,29.2-2
		c8.8-0.2,17.6-0.4,26.3-0.5c19.2-0.2,38.3,0.2,57.4,1.6c3.8,0.3,7.7,0.7,11.4,1.5c9.4,2.1,14.8,8.1,16.7,17.4
		c1.5,7.3,2.1,14.6,2.4,22c0.3,7,0.5,14,0.4,20.9c-0.2,11-0.6,22-2.8,32.8c-2.1,10.7-9,16.9-19.9,18.3c-9.7,1.3-19.4,1.7-29.2,2
		c-8.8,0.2-17.6,0.4-26.3,0.5C128.3,183.4,126.6,183.3,124.9,183.3C124.9,183.5,125,183.2,124.9,183.3z M108,149.3
		c14.5-8.2,28.8-16.4,43.3-24.6c-14.5-8.2-28.8-16.4-43.3-24.6C108,116.7,108,132.9,108,149.3z"
      />
      <path
        class="st0"
        d="M108,149.3c0-16.5,0-32.7,0-49.1c14.5,8.2,28.8,16.3,43.3,24.6C136.8,133,122.5,141.1,108,149.3z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "YouTube",
};
</script>


<style lang="scss" scoped>
.st0 {
  fill: #ff0000;
}
.st1 {
  fill: #ffffff;
}
</style>