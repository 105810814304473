<template>
  <svg
    version="1.1"
    id="Lager_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 250 250"
    style="enable-background: new 0 0 250 250"
    xml:space="preserve"
  >
    <circle class="st0" cx="124.7" cy="125.1" r="125" />
    <path
      class="st1"
      d="M101.8,191.1c55.4,0,85.7-45.9,85.7-85.7c0-1.3,0-2.6-0.1-3.9c5.9-4.3,11-9.6,15-15.6c-5.4,2.4-11.2,4-17.3,4.7
	c6.2-3.7,11-9.6,13.3-16.7c-5.8,3.5-12.3,6-19.1,7.3c-5.5-5.9-13.3-9.5-22-9.5c-16.6,0-30.1,13.5-30.1,30.1c0,2.4,0.3,4.7,0.8,6.9
	c-25-1.3-47.3-13.3-62.1-31.5c-2.6,4.5-4.1,9.6-4.1,15.1c0,10.5,5.3,19.7,13.4,25.1c-4.9-0.2-9.6-1.5-13.6-3.8c0,0.1,0,0.3,0,0.4
	c0,14.6,10.4,26.8,24.2,29.5c-2.5,0.7-5.2,1.1-7.9,1.1c-1.9,0-3.8-0.2-5.7-0.5c3.8,12,15,20.7,28.2,20.9
	c-10.3,8.1-23.3,12.9-37.4,12.9c-2.4,0-4.8-0.1-7.2-0.4C69,186.1,84.8,191.1,101.8,191.1"
    />
  </svg>
</template>

<script>
export default {
  name: "Twitter",
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: #1d9bf0;
}
.st1 {
  fill: #ffffff;
}
</style>