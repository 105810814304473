<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <title>Hide</title>
    <path
      d="M14.71,18.7l4-4a3,3,0,0,1-4,4ZM10,16a6,6,0,0,1,6-6,5.93,5.93,0,0,1,3.47,1.11l2.78-2.77A16,16,0,0,0,16,7C6.7,7,0,16,0,16a27.46,27.46,0,0,0,7.85,6.73l3.26-3.26A5.93,5.93,0,0,1,10,16Zm7.29-2.7a3,3,0,0,0-4,4Zm6.86-4-3.26,3.26A5.93,5.93,0,0,1,22,16a6,6,0,0,1-6,6,5.93,5.93,0,0,1-3.47-1.11L9.75,23.66A16,16,0,0,0,16,25c9.3,0,16-9,16-9A27.46,27.46,0,0,0,24.15,9.27Zm3.06-3.06L25.79,4.79,22.25,8.34a19.67,19.67,0,0,1,1.9.93ZM4.29,26.29l1.42,1.42,4-4.05a19.67,19.67,0,0,1-1.9-.93Z"
      fill="#7d8296"
    />
  </svg>
</template>

<script>
export default {
  name: "IconTemplate",
};
</script>

<style lang="scss" scoped>
</style>