
<template>
  <div v-if="!loading && isUserLoggedIn">
    <h2 class="profile-title">Profile settings</h2>

    <nav>
      <ul>
        <li :class="{ active: view == 'basics' }" @click="switchTab('basics')">
          <p class="large">Basics</p>
        </li>
        <li
          :class="{ active: view == 'account' }"
          @click="switchTab('account')"
        >
          <p class="large">Account</p>
        </li>
        <li
          :class="{ active: view == 'connected' }"
          @click="switchTab('connected')"
        >
          <p class="large">Connected apps</p>
        </li>
      </ul>
    </nav>
    <div class="view-container">
      <template v-if="view == 'basics'">
        <div class="profile-info-banner">
          <p>
            Personal profile pages will be launched this spring. Until then this
            is for your eyes only.
          </p>
        </div>
        <div class="profile-section">
          <div class="section-header">
            <h4>Profile information</h4>
            <p class="section-subtitle">The essentials.</p>
          </div>
          <div class="section-body">
            <DefaultTextInput
              v-model="user.firstName"
              :maxlength="50"
              title="First name"
              placeholder="First name"
              :invalid="user.firstName.length < 2"
            />

            <DefaultTextInput
              v-model="user.lastName"
              :maxlength="50"
              title="Last name"
              placeholder="Last name"
              :invalid="user.lastName.length < 2"
            />

            <div class="country">
              <p class="country-title">Country</p>
              <div class="flag-wrapper">
                <country-flag
                  v-if="user.profile.country !== null"
                  :country="user.profile.country.code"
                  :rounded="true"
                />

                <select
                  v-model="user.profile.country"
                  :class="{ 'no-country': user.profile.country == null }"
                >
                  <option :value="null">Choose country</option>
                  <option v-for="country in countries" :value="country">
                    {{ country.name }}
                  </option>
                </select>
              </div>
            </div>

            <DefaultTextInput
              v-model="user.profile.club"
              :maxlength="50"
              title="Club"
              placeholder="Club"
            />

            <DefaultTextInput
              v-model="user.profile.phone"
              :maxlength="50"
              title="Phone number"
              placeholder="Phone number"
            />
          </div>
        </div>
        <div class="profile-section">
          <div class="section-header">
            <h4>PDGA membership</h4>
            <p class="section-subtitle">
              Your PDGA membership number will show on leaderboards and be used
              for registration stages on sanctioned events.
            </p>
          </div>
          <div class="section-body">
            <DefaultNumberInput
              v-model="user.profile.pdgaNumber"
              title="PDGA number"
              placeholder="PDGA number"
            />
            <div class="title-with-tooltip">
              <p>PDGA rating</p>
              <TjingClickToolTip :forcedSize="'small'">
                <p>Rating updated daily</p>
              </TjingClickToolTip>
            </div>
            <DefaultTextInput
              class="no-margin"
              v-model="user.profile.pdgaRating"
              :disabled="true"
            />
          </div>
        </div>
        <div class="profile-section">
          <div class="section-header">
            <h4>Biography</h4>
            <p class="section-subtitle">
              You can add a biography and display your sponsors on your profile.
            </p>
          </div>
          <div class="section-body">
            <DefaultTextInput
              v-model="user.profile.sponsor"
              :maxlength="50"
              title="Sponsor"
              placeholder="Sponsor"
            />
            <div class="biography">
              <p>Biography</p>
              <textarea
                maxlength="1500"
                class="user-bio"
                placeholder="Biography"
                v-model="user.profile.biography"
              />
            </div>
          </div>
        </div>
        <div class="profile-section">
          <div class="section-header">
            <h4>Social links</h4>
            <p class="section-subtitle">
              You can display your social media accounts on your profile.
            </p>
          </div>
          <div class="section-body">
            <div class="social-with-icon">
              <Facebook />
              <p>Facebook</p>
            </div>

            <DefaultTextInput
              class="no-margin"
              v-model="user.profile.facebook"
              :maxlength="175"
              placeholder="https://Facebook.com/Username"
              @input="validateSocialLink('facebook', $event)"
              :invalid="socialErrors.facebook"
            />
            <div class="social-with-icon">
              <Instagram />
              <p>Instagram</p>
            </div>
            <DefaultTextInput
              class="no-margin"
              v-model="user.profile.instagram"
              :maxlength="175"
              placeholder="https://Instagram.com/Username"
              @input="validateSocialLink('instagram', $event)"
              :invalid="socialErrors.instagram"
            />
            <div class="social-with-icon">
              <YouTube />
              <p>Youtube</p>
            </div>
            <DefaultTextInput
              class="no-margin"
              v-model="user.profile.youtube"
              :maxlength="175"
              placeholder="https://Youtube.com/Channel"
              @input="validateSocialLink('youtube', $event)"
              :invalid="socialErrors.youtube"
            />
            <div class="social-with-icon">
              <TikTok />
              <p>TikTok</p>
            </div>
            <DefaultTextInput
              class="no-margin"
              v-model="user.profile.tiktok"
              :maxlength="175"
              placeholder="https://Tiktok.com/Username"
              @input="validateSocialLink('tiktok', $event)"
              :invalid="socialErrors.tiktok"
            />
            <div class="social-with-icon">
              <Twitter />
              <p>Twitter</p>
            </div>
            <DefaultTextInput
              class="no-margin"
              v-model="user.profile.twitter"
              :maxlength="175"
              placeholder="https://Twitter.com/Username"
              @input="validateSocialLink('twitter', $event)"
              :invalid="socialErrors.twitter"
            />
          </div>
        </div>

        <StandardSolidButton
          class="save-button"
          title="Save"
          :fluid="true"
          :desktopFluidity="true"
          :disabled="!profileBasicsSaveable"
          @click="saveBasics(user)"
        />
      </template>

      <template v-if="view == 'account'">
        <div class="profile-section">
          <div class="section-header">
            <h4>Log in</h4>
          </div>
          <div class="section-body">
            <div class="title-with-tooltip">
              <p>Email</p>
              <TjingClickToolTip :forcedSize="'small'">
                <p>
                  If you need to change your email send a message to
                  <a href="mailto:hello@tjing.se">Hello@tjing.se</a> and we’ll
                  help you.
                </p>
              </TjingClickToolTip>
            </div>
            <DefaultTextInput
              v-model="user.email"
              :maxlength="50"
              :disabled="true"
              placeholder="Email"
            />
            <div class="current-password-container">
              <p class="current-password">Current password</p>
              <span @click="showCurrentPassword = !showCurrentPassword">
                <Show v-if="showCurrentPassword" />
                <Hide v-else />
              </span>

              <input
                placeholder="Current password"
                autocomplete="current-password"
                :type="showCurrentPassword ? 'text' : 'password'"
                name="currentpassword"
                v-model="currentPassword"
                :class="{ invalid: passwordErrors.incorrect }"
                @blur="resetPasswordErrors()"
              />
            </div>
            <div class="new-password-container">
              <p class="new-password">New password</p>
              <span @click="showNewPassword = !showNewPassword">
                <Show v-if="showNewPassword" />
                <Hide v-else />
              </span>

              <input
                placeholder="New password"
                autocomplete="new-password"
                :type="showNewPassword ? 'text' : 'password'"
                name="newPassword"
                @blur="resetPasswordErrors()"
                v-model="newPassword"
                :class="{ invalid: passwordErrors.toShort }"
              />
            </div>
            <div class="errors">
              <div v-if="passwordErrors.toShort">
                <p>
                  <b
                    >Minimum of 8 characters, atleast one letter end one
                    number.</b
                  >
                </p>
              </div>
              <div v-if="passwordErrors.incorrect">
                <p>
                  <b>That is not your current Password, please try again</b>
                </p>
              </div>
            </div>
            <StandardSolidButton
              class="save-password"
              title="Update password"
              :fluid="true"
              @click="savePassword()"
            />
          </div>
        </div>
        <div class="profile-section">
          <div class="section-header">
            <h4>Pin</h4>
            <p class="section-subtitle">
              Some events require you to sign your result using Tjing’s Digital
              Signature.
              <a href="https://support.tjing.se/signature/" target="_blank"
                >Read more</a
              >
            </p>
          </div>
          <div class="section-body">
            <div class="new-pin-container">
              <p class="new-pin">PIN (4 digits)</p>
              <span @click="showPin = !showPin">
                <Show v-if="showPin" /> <Hide v-else
              /></span>

              <input
                placeholder="Add pin"
                maxlength="4"
                :class="{ invalid: pinErrors.toShort || pinErrors.toEasy }"
                autocomplete="new-password"
                :type="showPin ? 'text' : 'password'"
                name="pincode"
                @keypress="onlyNumber"
                v-model="enteredPin"
                @blur="restePinErrors()"
              />
            </div>
            <div class="errors">
              <div class="only-digits" v-if="pinErrors.toShort">
                <p><b>Your PIN must be 4 digits.</b></p>
              </div>
              <div class="only-digits" v-if="pinErrors.toEasy">
                <p><b>Combinations like 1111 or 1234 is not allowed.</b></p>
              </div>
            </div>
            <StandardSolidButton
              class="save-password"
              title="Save"
              :fluid="true"
              @click="savePin()"
            />
          </div>
        </div>
        <div class="profile-section">
          <div class="section-header">
            <h4>Privacy</h4>
          </div>
          <div class="section-body">
            <div class="public-profile">
              <p>Private profile</p>
              <TjingClickToolTip :forcedSize="'small'">
                <p>
                  When your profile is set to private, only your name, PDGA
                  number and PDGA rating will be public.
                </p>
              </TjingClickToolTip>
              <DefaultToggle
                :value="!user.profile.isPublic"
                @input="togglePublicProfile($event)"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-if="view == 'connected'">
        <div class="profile-section">
          <div class="section-header">
            <h4>Third-party apps</h4>
            <p class="section-subtitle">
              When connected these apps will share some of your Tjing data in
              order to receive benefits from your membership.
            </p>
          </div>
          <div class="section-body">
            <div class="stripe-connector">
              <template v-if="!returnFromStripe">
                <div class="stripe-with-icon">
                  <Stripe />
                  <p>Stripe</p>
                </div>

                <TjingClickToolTip :forcedSize="'small'">
                  <p>
                    To use Tjing’s Direct Payments and collect registration fees
                    you need to connect your Stripe account to Tjing.
                  </p>
                </TjingClickToolTip>
                <a :href="stripeUrl()">Connect</a>
              </template>
              <template v-else>
                <div class="connect-account">
                  <form autocomplete="off">
                    <DefaultTextInput
                      v-model="stripeAccountName"
                      :maxlength="50"
                      placeholder="Add a Stripe account name"
                    />
                  </form>
                  <StandardSolidButton
                    title="Save"
                    :fluid="false"
                    :desktop-fluidity="true"
                    :disabled="stripeAccountName.length <= 3"
                    @click="createConnectedAccount()"
                  />
                </div>
              </template>
            </div>
            <div class="connected-accounts">
              <div class="title-with-tooltip">
                <p>Connected accounts</p>
                <TjingClickToolTip :forcedSize="'small'">
                  <p>
                    These are your connected accounts. You can use any of these
                    to collect registration fees from your events.
                  </p>
                </TjingClickToolTip>
              </div>
              <ul class="connected-accounts-list">
                <li
                  v-for="(account, index) in user.connectedAccounts"
                  :key="index"
                  v-if="user.connectedAccounts.length > 0"
                >
                  <p>
                    <b>{{ account.name }}</b>
                  </p>
                  <p><a target="_blank" href="https://stripe.com">Visit</a></p>
                </li>
                <li v-else>
                  <p>No accounts have been connected</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import DefaultTextInput from "@/components/UIElements/DefaultTextInput";
import DefaultNumberInput from "@/components/UIElements/DefaultNumberInput";
import TjingClickToolTip from "@/components/UIElements/TjingClickToolTip";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import DefaultToggle from "@/components/UIElements/DefaultToggle";
import Show from "@/assets/icons/Show";
import Hide from "@/assets/icons/Hide";
import Facebook from "@/assets/icons/Facebook";
import Instagram from "@/assets/icons/Instagram";
import YouTube from "@/assets/icons/YouTube";
import TikTok from "@/assets/icons/TikTok";
import Twitter from "@/assets/icons/Twitter";
import Stripe from "@/assets/icons/Stripe";
import validator from "validator";

export default {
  name: "Profile",
  token: "home",
  components: {
    TjingClickToolTip,
    DefaultTextInput,
    StandardSolidButton,
    Show,
    Hide,
    DefaultToggle,
    CountryFlag,
    Facebook,
    Instagram,
    YouTube,
    TikTok,
    Twitter,
    Stripe,
    DefaultNumberInput,
  },
  data: function () {
    return {
      view: "basics",
      loading: true,
      enteredPin: "",
      showPin: false,
      newPassword: "",
      currentPassword: "",
      showCurrentPassword: false,
      showNewPassword: false,
      countries: null,
      pinErrors: {
        toShort: false,
        toEasy: false,
      },
      passwordErrors: {
        incorrect: false,
        toShort: false,
        toEasy: false,
      },
      user: {},
      showPinAdder: false,
      changePassword: false,
      returnFromStripe: false,
      stripeAccountName: "",
      socialErrors: {
        facebook: false,
        instagram: false,
        youtube: false,
        tiktok: false,
        twitter: false,
      },
    };
  },
  computed: {
    profileBasicsSaveable() {
      // check name
      if (this.user.firstName.length < 2) return false;
      if (this.user.lastName.length < 2) return false;

      //check socials
      if (this.socialErrors.facebook == true) return false;
      if (this.socialErrors.instagram == true) return false;
      if (this.socialErrors.youtube == true) return false;
      if (this.socialErrors.tiktok == true) return false;
      if (this.socialErrors.twitter == true) return false;

      return true;
    },
    showMessage() {
      return this.$store.getters.showMessage;
    },
    showModals() {
      return this.$store.getters.modalStatus;
    },
    isUserLoggedIn() {
      return this.$store.getters.isUserLoggedIn;
    },
  },
  methods: {
    validateSocialLink(social, url) {
      const validatorOptions = {
        require_protocol: true,
        protocols: ["https"],
      };

      if (validator.isURL(url, validatorOptions) || url == "" || url == null) {
        this.socialErrors[social] = false;
      } else {
        this.socialErrors[social] = true;
      }
    },
    restePinErrors() {
      this.pinErrors.toShort = false;
      this.pinErrors.toEasy = false;
    },
    resetPasswordErrors() {
      this.passwordErrors.toShort = false;
      this.passwordErrors.incorrect = false;
    },
    switchTab(view) {
      if (this.$route.params.view !== view) {
        this.$router.push({ name: "profile", params: { view } });
        this.view = view;
      }
    },
    async togglePublicProfile(bool) {
      try {
        const updatedStatus = await this.$axios({
          headers: {
            Authorization: this.$store.state.user.sessionInfo.token,
          },
          data: {
            query: `
              mutation UpdateMeUser($UpdateMeUser: UpdateMeUserInput!){
                UpdateMeUser(input:$UpdateMeUser){
                  id
                }
              }
            `,
            variables: {
              UpdateMeUser: {
                isPublicProfile: !bool,
              },
            },
          },
        });

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Saved",
          type: "success",
        });
        this.loadProfile();
      } catch (err) {
        console.log(err);
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async savePassword() {
      if (this.newPassword.length < 8) {
        this.passwordErrors.toShort = true;
      } else {
        try {
          const newPasswordRequest = await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
            mutation {
              ChangePassword(currentPassword:"${this.currentPassword}",newPassword:"${this.newPassword}")
            }
            `,
            },
          });

          if (newPasswordRequest.data.errors) {
            if (
              newPasswordRequest.data.errors[0].message == "Incorrect password"
            ) {
              this.passwordErrors.incorrect = true;
            } else if (
              newPasswordRequest.data.errors[0].message ==
              "Minimum eight characters, at least one letter and one number"
            ) {
              this.passwordErrors.toShort = true;
            }
          } else {
            this.closePasswordModal();

            this.$store.dispatch("showMessage", {
              show: true,
              message: "Password updated",
              type: "success",
            });
          }
        } catch (err) {
          console.log(err);
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });
        }
      }
    },
    async savePin() {
      if (this.enteredPin.length != 4) {
        this.pinErrors.toShort = true;
      } else {
        try {
          const updatedPin = await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
              mutation UpdateMeUser($UpdateMeUser: UpdateMeUserInput!){
                UpdateMeUser(input:$UpdateMeUser){
                  id
                }
              }
            `,
              variables: {
                UpdateMeUser: {
                  pinCode: this.enteredPin,
                },
              },
            },
          });

          if (
            updatedPin.data.errors &&
            updatedPin.data.errors[0].message == "Invalid pin code"
          ) {
            this.pinErrors.toEasy = true;
          } else {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Pin updated",
              type: "success",
            });
            this.loadProfile();
          }
        } catch (err) {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });
        }
      }
    },
    async saveBasics(user) {
      try {
        const updatedBasics = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
              mutation UpdateMeUser($UpdateMeUser: UpdateMeUserInput!){
                UpdateMeUser(input:$UpdateMeUser){
                  id
                }
              }
            `,
            variables: {
              UpdateMeUser: {
                firstName: user.firstName,
                lastName: user.lastName,
                phone: user.profile.phone || null,
                club: user.profile.club || null,
                sponsor: user.profile.sponsor || null,
                country: user.profile.country?.code || null,
                pdgaNumber: user.profile.pdgaNumber
                  ? parseInt(user.profile.pdgaNumber)
                  : null,
                biography: user.profile.biography || null,
                facebook: user.profile.facebook || null,
                instagram: user.profile.instagram || null,
                tiktok: user.profile.tiktok || null,
                youtube: user.profile.youtube || null,
                twitter: user.profile.twitter || null,
              },
            },
          },
        });

        if (updatedBasics.data.errors) {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });
        } else {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Profile updated",
            type: "success",
          });
        }
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
      this.loadProfile();
    },
    stripeUrl() {
      return process.env.VUE_APP_STRIPE_ACCOUNT_CREATION_URL_PROFILE;
    },
    createConnectedAccount() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation{
            CreateConnectedAccount(authorizationCode:"${this.$router.currentRoute.query.code}", name:"${this.stripeAccountName}"){
              id
              name
              createdAt
              owner{
                id
                firstName
                lastName
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          this.$router.replace({ query: {} });
          this.returnFromStripe = false;
          this.loadProfile();
        })
        .catch(() => {
          this.$emit("connectionError");
        });
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 13) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    userInitials() {
      return this.user.firstName.charAt(0) + this.user.lastName.charAt(0);
    },
    loadProfile() {
      this.$store.dispatch("toggleLoading", true);

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
            {
              countries{
                name
                code
              }
              me{
                id
                firstName
                lastName
                email
                profile{
                  club
                  pdgaNumber
                  pdgaRating
                  pinCode
                  phone
                  country{
                    name
                    code
                  }
                  instagram
                  facebook
                  twitter
                  youtube
                  tiktok
                  twitter
                  sponsor
                  biography
                  isPublic
                }
                connectedAccounts{
                  id
                  name
                  owner{
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          `,
        },
      }).then((result) => {
        this.countries = result.data.data.countries;
        this.user = result.data.data.me;
        this.loading = false;
        this.$store.dispatch("toggleLoading", false);
      });
    },
  },
  beforeMount() {
    if (this.isUserLoggedIn == false) {
      this.$router.push({ name: "login", params: { source: "profile" } });
    } else {
      this.loadProfile();
      if (this.$router.currentRoute.query.code) {
        this.returnFromStripe = true;
        this.view = "connected";
      } else {
        this.view = this.$route.params.view;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";
.profile-info-banner {
  background: $snow;
  margin: 24px 12px 0 12px;
  border-radius: 6px;
  border: 1px solid $fog;
  padding: 12px;
}
.social-with-icon,
.stripe-with-icon {
  display: flex;
  align-items: center;
  margin: 18px 0 10px 0;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
.stripe-with-icon {
  margin: 0;
}

.current-password-container,
.new-password-container,
.new-pin-container {
  input {
    &.invalid {
      border: 1px solid $dusk;
      box-shadow: 0 0 0px 1px $dusk;

      &:hover,
      &:focus {
        border: 1px solid $dusk;
        box-shadow: 0 0 0px 1px $dusk;
      }
    }
  }
}

nav {
  border-bottom: 1px solid $fog;
  margin: 0 12px 32px 12px;
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    li {
      margin-right: 24px;
      padding-top: 2px;
      display: flex;
      align-items: center;
      height: 54px;
      border-bottom: 2px solid transparent;
      transition: all ease 0.3s;
      cursor: pointer;
      user-select: none;

      &:hover {
        border-bottom: 2px solid $fog;
      }

      &.active {
        border-bottom: 2px solid $midnight;
      }
    }
  }
}
.profile-section {
  padding: 32px 0px 0 0px;
  margin: 32px 12px 0 12px;
  border-top: 1px solid $fog;

  &:first-of-type {
    border-top: none;
    margin-top: 0;
    padding: 0px;
  }

  .section-subtitle {
    color: $strom;
    margin-top: 8px;
  }
  .default-inputfield {
    margin-top: 18px;

    &.no-margin {
      margin-top: 0;
    }
  }
  .country {
    margin-top: 18px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .country-title {
      width: 100%;
      font-size: 1rem;
      margin-bottom: 10px;
    }

    .flag-wrapper {
      width: 100%;
      border-radius: 6px;
      position: relative;

      .normal-flag {
        background-color: white;
        display: block;
        position: absolute;
        transform: scale(0.5);
        left: 15px;
        top: 4px;
        z-index: 2;
      }
    }

    select {
      border-radius: 6px;
      width: 100%;
      padding-left: 54px;
      margin: 0;
      z-index: 0;

      option {
        padding-left: 0;
      }

      &.no-country {
        width: 100%;
        padding-left: 15px;
      }
    }
  }
  .title-with-tooltip {
    margin: 18px 0 10px 0;
  }

  .current-password-container,
  .new-password-container,
  .new-pin-container {
    margin-top: 18px;
    p {
      font-size: 1rem;
    }
  }
}
.save-button {
  margin: 24px 12px 0 auto;
}
.save-password {
  margin: 24px 0px 0 auto;
}

.public-profile {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .tooltip-container {
    margin-left: 8px;
  }
  .default-toggle {
    margin-left: auto;
  }
}

.connect-account {
  width: 100%;
  .StandardBorderedButton {
    margin-left: auto;
    margin-top: 18px;
  }
}
.connected-accounts {
  margin-top: 24px;
  padding-top: 6px;
  border-top: 1px solid $fog;
  ul {
    list-style: none;
  }
  li {
    display: list-item;
    align-items: center;
    width: 100%;
    padding: 18px 0;
    display: flex;
    justify-content: space-between;

    &:not(:first-of-type) {
      border-top: 1px solid $fog;
    }
  }
}
.stripe-connector {
  display: flex;
  align-items: center;
  margin-top: 18px;
  p {
    font-size: 1rem;
  }
  a {
    margin-left: auto;
  }
  .tooltip-container {
    margin-left: 8px;
  }
}

.new-password-container {
  margin-top: 18px;
}

.current-password-container,
.new-password-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
  }

  input {
    border-radius: 6px;
    border: 1px solid $blizzard;
    width: 100%;
    height: 48px;
    padding: 0 15px;
    margin-top: 10px;
    @include Gilroy-Bold;
    transition: all ease 0.3s;

    &::placeholder {
      @include Gilroy-Regular;
    }

    &:focus {
      outline: none;
    }
  }
}

.password-management {
  margin-top: 24px;
  > p {
    font-size: 1rem;
  }
}

.errors {
  margin-top: 18px;
  color: $dusk;
}
.new-pin-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  svg {
    width: 24px;
    height: 24px;
  }

  span {
    cursor: pointer;
  }

  input {
    height: 48px;
    margin-top: 10px;
    border: 1px solid $blizzard;
    width: 100%;
    transition: all ease 0.3s;
    border-radius: 6px;
    padding: 0 15px;

    &::placeholder {
      color: $blizzard;
      font-size: 16px;
    }
    &:focus,
    &:hover {
      border: 1px solid $midnight;
    }
  }
}

.profile-title {
  padding: 0 0 0 12px;
  margin: 32px 0 32px 0;
}

.title-with-tooltip {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .default-inputfield {
    margin: 0;
  }
  p {
    margin-right: 8px;
    font-size: 16px;
  }
}

.biography {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  textarea {
    border-radius: 6px;
    border: 1px solid $blizzard;
    padding: 12px 15px;
    min-height: 150px;
    transition: all ease 0.3s;
    width: 100%;
    @include Gilroy-Bold;
    &::placeholder {
      @include Gilroy-Medium;
      color: $blizzard;
    }

    &:hover,
    &:focus {
      border: 1px solid $midnight;
    }
  }
}
@media (min-width: 1200px) {
  .stripe-connector {
    .connect-account {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      form {
        width: 60%;
        display: inline-block;
      }
      .StandardBorderedButton {
        margin: 0;
      }
    }
  }
  .view-container {
    display: flex;
    flex-direction: column;
  }
  .profile-info-banner {
    margin-top: 0px;
  }
  .biography {
    margin-top: 24px;
  }
  .social-with-icon,
  .stripe-with-icon {
    margin: 24px 0 10px 0;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
  .stripe-with-icon {
    margin: 0;
  }
  .profile-title {
    margin: 32px 0 48px 0;
  }
  nav {
    border-top: 1px solid $fog;
    margin-bottom: 64px;
  }
  .profile-section {
    display: flex;
    justify-content: space-between;
    .section-header,
    .section-body {
      width: 45%;
    }
  }
  .section-body {
    .title-with-tooltip {
      margin: 24px 0 10px 0;
    }

    .default-inputfield {
      margin-top: 24px;
      width: 100%;
      &:first-of-type {
        margin-top: 0;
      }
    }
    .new-pin-container {
      margin-top: 0;
    }
  }
}
</style>

