<template>
  <svg
    version="1.1"
    id="Lager_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 250 250"
    style="enable-background: new 0 0 250 250"
    xml:space="preserve"
  >
    <g>
      <path
        d="M125.2,22.7c33.4,0,37.3,0.1,50.5,0.7c12.2,0.6,18.8,2.6,23.2,4.3c5.8,2.3,10,5,14.4,9.4c4.4,4.4,7.1,8.5,9.4,14.4
		c1.7,4.4,3.7,11,4.3,23.2c0.6,13.2,0.7,17.1,0.7,50.5s-0.1,37.3-0.7,50.5c-0.6,12.2-2.6,18.8-4.3,23.2c-2.3,5.8-5,10-9.4,14.4
		c-4.4,4.4-8.5,7.1-14.4,9.4c-4.4,1.7-11,3.7-23.2,4.3c-13.2,0.6-17.1,0.7-50.5,0.7s-37.3-0.1-50.5-0.7c-12.2-0.6-18.8-2.6-23.2-4.3
		c-5.8-2.3-10-5-14.4-9.4c-4.4-4.4-7.1-8.5-9.4-14.4c-1.7-4.4-3.7-11-4.3-23.2c-0.6-13.2-0.7-17.1-0.7-50.5s0.1-37.3,0.7-50.5
		c0.6-12.2,2.6-18.8,4.3-23.2c2.3-5.8,5-10,9.4-14.4c4.4-4.4,8.5-7.1,14.4-9.4c4.4-1.7,11-3.7,23.2-4.3
		C87.9,22.8,91.9,22.7,125.2,22.7 M125.2,0.1C91.3,0.1,87,0.3,73.7,0.9C60.4,1.5,51.3,3.6,43.3,6.7c-8.2,3.2-15.2,7.5-22.1,14.4
		C14.3,28.1,10,35,6.8,43.3c-3.1,8-5.2,17-5.8,30.3c-0.6,13.3-0.8,17.6-0.8,51.5c0,33.9,0.1,38.2,0.8,51.5
		C1.6,190,3.7,199.1,6.8,207c3.2,8.2,7.5,15.2,14.4,22.1c6.9,6.9,13.9,11.2,22.1,14.4c8,3.1,17,5.2,30.3,5.8
		c13.3,0.6,17.6,0.8,51.5,0.8s38.2-0.1,51.5-0.8c13.3-0.6,22.4-2.7,30.3-5.8c8.2-3.2,15.2-7.5,22.1-14.4
		c6.9-6.9,11.2-13.9,14.4-22.1c3.1-8,5.2-17,5.8-30.3c0.6-13.3,0.8-17.6,0.8-51.5s-0.1-38.2-0.8-51.5c-0.6-13.3-2.7-22.4-5.8-30.3
		c-3.2-8.2-7.5-15.2-14.4-22.1c-6.9-6.9-13.9-11.2-22.1-14.4c-8-3.1-17-5.2-30.3-5.8C163.4,0.3,159.2,0.1,125.2,0.1L125.2,0.1z"
      />
      <path
        d="M125.2,60.9C89.8,60.9,61,89.7,61,125.1s28.7,64.2,64.2,64.2s64.2-28.7,64.2-64.2S160.7,60.9,125.2,60.9z M125.2,166.8
		c-23,0-41.7-18.7-41.7-41.7s18.7-41.7,41.7-41.7c23,0,41.7,18.7,41.7,41.7S148.2,166.8,125.2,166.8z"
      />
      <circle cx="192" cy="58.4" r="15" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Instagram",
};
</script>

<style lang="scss" scoped>
</style>