<template>
  <svg
    version="1.1"
    id="Lager_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 250 250"
    style="enable-background: new 0 0 250 250"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          class="st0"
          d="M56.1,0h137.8c31,0,56.1,25.1,56.1,56.1v137.8c0,31-25.1,56.1-56.1,56.1H56.1C25.1,250,0,224.9,0,193.9V56.1
			C0,25.1,25.1,0,56.1,0z"
        />
        <path
          class="st1"
          d="M115.2,97c0-6,4.9-8.7,12.9-8.7c13.2,0.3,26.2,3.6,37.8,9.8V62.7c-12-4.7-24.8-7.1-37.8-6.9
			c-30.8,0-51.4,16.1-51.4,43.3c0,42.1,57.8,35.2,57.8,53.4c0,7-6.1,9.3-14.5,9.3c-14.8-0.8-29.2-5.1-42.1-12.4v36.3
			c13.1,5.7,27.3,8.6,41.6,8.7c31.6,0,53.4-15.6,53.4-43.3C173.2,105.9,115.2,114,115.2,97z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Stripe",
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: #6772e5;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
</style>