<template>
  <svg
    version="1.1"
    id="Lager_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 250 250"
    style="enable-background: new 0 0 250 250"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M250,125.1c0-69-56-125-125-125S0,56,0,125.1c0,62.4,45.7,114.1,105.5,123.5v-87.3H73.8v-36.1h31.7V97.5
		c0-31.3,18.7-48.6,47.2-48.6c13.7,0,28,2.4,28,2.4v30.8h-15.8c-15.5,0-20.4,9.6-20.4,19.5v23.4h34.7l-5.5,36.1h-29.1v87.3
		C204.3,239.2,250,187.5,250,125.1z"
      />
      <path
        class="st1"
        d="M173.7,161.2l5.5-36.1h-34.7v-23.4c0-9.9,4.8-19.5,20.4-19.5h15.8V51.3c0,0-14.3-2.4-28-2.4
		c-28.6,0-47.2,17.3-47.2,48.6v27.5H73.8v36.1h31.7v87.3c6.4,1,12.9,1.5,19.5,1.5c6.6,0,13.2-0.5,19.5-1.5v-87.3H173.7z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Facebook",
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: #1877f2;
}
.st1 {
  fill: #ffffff;
}
</style>