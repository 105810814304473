<template>
  <div class="default-inputfield">
    <input
      :class="{ invalid: invalid || showError }"
      type="text"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      name="number-input"
      @change="updateValue($event.target.value)"
      @keypress="onlyNumber"
      :value="value"
      :disabled="disabled"
      maxlength="15"
    />
  </div>
</template>

<script>
export default {
  name: "DefaultNumberInput",
  props: ["placeholder", "value", "disabled", "invalid", "autocomplete"],
  data() {
    return {
      showError: false,
    };
  },
  computed: {},
  methods: {
    flashError() {
      this.showError = true;

      setTimeout(() => {
        this.showError = false;
      }, 400);
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 13 && keyCode !== 46) {
        // 46 is dot
        this.flashError();
        $event.preventDefault();
      } else if (keyCode == 13) {
        this.updateValue($event.target.value);
        $event.target.blur();
      }
    },
    updateValue: function (value) {
      this.$emit("input", parseFloat(value) || null);
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
// iOS input shadow removal
input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input {
  height: 48px;
  border: 1px solid $blizzard;
  outline: none;
  border-radius: 6px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  transition: all ease 0.3s;
  @include Gilroy-Bold;
  background-color: transparent;
  text-overflow: ellipsis;

  &.invalid {
    border: 1px solid $dusk;
    box-shadow: 0 0 0px 1px $dusk;

    &:hover,
    &:focus {
      border: 1px solid $dusk;
      box-shadow: 0 0 0px 1px $dusk;
    }
  }

  &:disabled {
    color: $strom;
    background-color: $mist;
    -webkit-text-fill-color: $strom;
    opacity: 1;

    &::placeholder {
      color: $blizzard;
      opacity: 1;
      transition: all ease 0.3s;
    }
  }

  &::placeholder {
    color: $blizzard;
    opacity: 1;
    transition: all ease 0.3s;
    @include Gilroy-Medium;
  }

  &:hover,
  &:focus {
    border: 1px solid $midnight;
    transition: all ease 0.3s;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .default-inputfield {
    width: calc(50% - 20px);
  }
}
</style>
