<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <title>Show</title>
    <path
      d="M32,16s-6.7,9-16,9S0,16,0,16,6.7,7,16,7,32,16,32,16ZM16,10a6,6,0,1,0,6,6A6,6,0,0,0,16,10Zm0,3a3,3,0,1,0,3,3A3,3,0,0,0,16,13Z"
      fill="#7d8296"
    />
  </svg>
</template>

<script>
export default {
  name: "Show",
};
</script>

<style lang="scss" scoped>
</style>