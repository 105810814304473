<template>
  <svg
    version="1.1"
    id="Lager_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 250 250"
    style="enable-background: new 0 0 250 250"
    xml:space="preserve"
  >
    <g>
      <circle cx="125" cy="125" r="125" />
    </g>
    <g>
      <g>
        <path
          class="st0"
          d="M108.4,107.6v-6.8c-2.3-0.3-4.6-0.5-6.9-0.5c-28.4,0-51.5,23.1-51.5,51.5c0,17.4,8.7,32.8,22,42.1
			c-8.6-9.2-13.7-21.5-13.7-35C58.3,130.9,80.6,108.3,108.4,107.6z"
        />
        <path
          class="st0"
          d="M109.6,182.5c12.7,0,23.1-10.2,23.6-22.8l0.1-111.9l20.3,0l0-0.2c-0.4-2.3-0.7-4.7-0.7-7.1h-28l0,112.1
			c-0.5,12.5-10.8,22.6-23.5,22.6c-3.9,0-7.6-1-10.9-2.7C94.9,178.5,101.8,182.5,109.6,182.5z"
        />
        <path
          class="st0"
          d="M191.9,86.1v-6.6c-7.8,0-15.1-2.3-21.2-6.3C176.1,79.5,183.5,84.3,191.9,86.1z"
        />
        <path
          class="st1"
          d="M170.7,73.2c-6-6.8-9.6-15.8-9.6-25.5h-7.6C155.5,58.4,161.9,67.5,170.7,73.2z"
        />
        <path
          class="st1"
          d="M101.5,128.1c-13,0-23.7,10.8-23.7,23.7c0,9,5.3,16.9,12.8,20.8c-2.8-3.9-4.5-8.6-4.5-13.7
			c0-13,10.5-23.5,23.5-23.5c2.4,0,4.7,0.4,6.9,1.1v-28.5c-2.3-0.3-4.6-0.5-6.9-0.5c-0.4,0-0.8,0-1.2,0l-0.1,0l-0.1,21.6
			C106,128.4,103.9,128.1,101.5,128.1z"
        />
        <path
          class="st1"
          d="M191.9,85.7l-0.1,0l0,21.6c-14.5,0-28-4.8-38.9-12.6l0,57.1c0,28.4-22.9,51.3-51.3,51.3
			c-11,0-21.1-3.3-29.5-9.2c9.4,10.1,22.8,16.4,37.6,16.4c28.4,0,51.5-23.1,51.5-51.5v-56.8c11,7.9,24.4,12.5,38.9,12.5v-28
			C197.2,86.6,194.5,86.3,191.9,85.7z"
        />
        <path
          class="st2"
          d="M153,151.8V95c11,7.9,24.4,12.5,38.9,12.5V85.7c-8.4-1.8-15.8-6.3-21.2-12.5c-8.8-5.7-15.1-14.9-17.1-25.5
			h-20.5l0,112.1c-0.5,12.6-10.8,22.6-23.5,22.6c-7.8,0-14.8-3.9-19.1-9.8c-7.5-3.9-12.6-11.8-12.6-20.8c0-13,10.5-23.5,23.5-23.5
			c2.4,0,4.7,0.4,6.9,1.1v-21.9c-27.8,0.7-50.3,23.5-50.3,51.4c0,13.5,5.3,25.9,13.8,35c8.4,5.9,18.5,9.3,29.5,9.3
			C129.9,203.3,153,180.2,153,151.8z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "TikTok",
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: #25f4ee;
}
.st1 {
  fill: #fe2c55;
}
.st2 {
  fill: #ffffff;
}
</style>